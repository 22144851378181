<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Guru
              </span>
            </h3>
            <div class="card-toolbar">
              <span
                @click="modalImport = true"
                class="btn btn-warning font-weight-bolder font-size-sm mr-2"
              >
                Import Data Guru</span
              >
              <router-link
                :to="`/guru/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Guru Baru</router-link
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :filter="filter"
              @filtered="onFiltered"
              :items="currentUserList.data"
              :fields="fields"
              :busy="isLoading"
              show-empty
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              responsive
            >
              <template #empty="scope">
                <h6 class="text-center">Tidak ada Guru ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada Guru ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #cell(action)="data">
                <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <div class="navi-link" @click="goToDetail(data.item)">
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Guru</span>
                      </div>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deleteItem(data)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Guru</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalImport"
      id="modal-update"
      cancel-variant="outline-secondary"
      ok-title="Konfirmasi"
      cancel-title="Batalkan"
      centered
      @ok="importFile"
      title="Import Data"
    >
      <validation-observer ref="formImport">
        <b-form id="form-upload-document" @submit.prevent="importFile">
          <div class="d-flex flex-row mb-1">
            <b-button
              type="button"
              @click="downloadTemplate"
              class="
                btn btn-fh btn-light-info btn-hover-info
                border border-info
                font-weight-bold
                px-2 px-lg-5
              "
            >
              <span class="svg-icon svg-icon-info svg-icon-lg">
                <!--begin::Svg-->
                <inline-svg src="media/svg/icons/General/Clip.svg" />
              </span>
              Sample Data Guru.xlsx
            </b-button>
          </div>
          <b-form-group
            id="input-group-3"
            label="File (Excel)"
            label-for="input-3"
          >
            <validation-provider
              #default="{ errors }"
              name="File (Excel)"
              rules="required"
            >
              <b-form-file
                ref="file"
                v-model="fileImport"
                :state="errors.length > 0 ? false : null"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".csv,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";

import { USER_SELECTED } from "@/core/services/store/auth.module";
import mixin from "@/core/services/Helpers";
import Swal from "sweetalert2";

import {
  GET_USER_LIST,
  ROLE_TEACHER,
  DELETE_TEACHER,
  GET_TEACHER_TEMPLATE,
  IMPORT_USER,
} from "@/core/services/store/user.module";
export default {
  name: "GuruList",
  mixins: [mixin],
  data() {
    return {
      fileImport: null,
      modalImport: false,
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: "",
      timeoutFilter : null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama Guru",
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Guru" }]);
    this.getData();
  },
     watch: {
    perPage: function () {
        this.getData()
    },
    currentPage: function () {
      this.getData();
    },
    filter : function () {
     this.isLoading = true;
     clearTimeout(this.timeoutFilter);
      this.timeoutFilter = setTimeout(() => {
        this.getData();
      },1500);
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.user.errors,
    }),
    ...mapGetters(["currentUserList"]),
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return false;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },

  methods: {
    importFile() {
      this.$refs.formImport.validate().then(async (success) => {
        if (success) {
          const overlay = this.$loadingOverlay.show();
          this.$store
            .dispatch(IMPORT_USER, {
              isSiswa: false,
              body: {
                file: this.fileImport,
                vendor_id: this.vendorId,
              },
            })
            .then(() => {
              overlay.hide();
              this.getData();
              Swal.fire({
                title: "",
                text: "Import data guru berhasil!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            })
            .catch(() => {
              overlay.hide();
              this.$bvToast.toast(this.errors, {
                title: `Gagal Import data guru`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    downloadTemplate() {
      this.isLoading = true;
      // const params = new URLSearchParams();
      // params.append("vendor_id", this.vendorId);

      console.log(`${GET_TEACHER_TEMPLATE}${this.vendorId}`);

      window.location.href = `${GET_TEACHER_TEMPLATE}${this.vendorId}`;

      // this.$store
      //   .dispatch(GET_TEACHER_TEMPLATE, params.toString())
      //   .then((response) => {
      //     this.isLoading = false;

      //     const url = URL.createObjectURL(new Blob([response]));

      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", "contoh.xlsx");
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //     this.$bvToast.toast(this.errors, {
      //       title: `Gagal mengunduh contoh`,
      //       variant: "danger",
      //       solid: true,
      //     });
      //   });
      this.isLoading = false;
    },
    goToDetail(item) {
      this.$store.dispatch(USER_SELECTED, item);
      this.$router.push(`/guru/${item.teacher._id}/edit`);
    },
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map((item) => {
        return item.kelas.nama_kelas;
      });
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = this.currentUserList.total;
      this.currentPage = 1;
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = this.currentUserList.total;
      item.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("where['role']", ROLE_TEACHER);

      params.append("where['name']",this.filter);
      params.append("page", this.currentPage);
      params.append("limit", this.perPage);



      this.$store
        .dispatch(GET_USER_LIST, params.toString())
        .then((response) => {
          
          console.log(response.data);


          this.isLoading = false;
          this.totalRows = this.currentUserList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Guru`,
            variant: "danger",
            solid: true,
          });
        });
    },
    setActiveTab(event, item) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      item.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    deleteItem(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus guru ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_TEACHER, item.item.teacher._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus guru`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Publish Ujian`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>

<style scoped>
</style>
